<template>
  <div>
    <loading v-show="load" text="Carregando os dados"/>
    <div v-show="!load">
      <h2 class="font-weight-bold">
        {{dados.nome_departamento}}
        <span v-if="!dados.nome_departamento">Departamento da Educação</span>
      </h2>
      <modal-menu :menus="menus" :selected="menu" @changeMenu="(m) => {this.menu = m}"/>
      <div v-show="menu === 1">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="nomeDepartamento">Nome do Departamento</label>
              <input type="text" class="form-control" id="nomeDepartamento" placeholder="Ex: Departamento da Educação" v-model="dados.nome_departamento">
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="emailResponsavel">Email Responsável</label>
              <input type="text" class="form-control" id="emailResponsavel" placeholder="Ex: Informe o email do departamento" v-model="dados.email_responsavel">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="statusDepartamento">Status</label>
              <select name="status" id="statusDepartamento" class="form-control" v-model="dados.status">
                <option value="1">Ativo</option>
                <option value="0">Desativado</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMenu from "../../components/commons/ModalMenu";
import UploadImage from "../../components/UploadImage";
import Loading from "../../components/commons/Loading";

export default {
  name: 'DepartamentoFormulario',
  components: {Loading, UploadImage, ModalMenu},
  data() {
    return {
      load: false,
      token: localStorage.getItem('key'),
      editar: false,
      menus: [
        { nome: 'Dados', active: true },
      ],
      menu: 1,
      dados: {
        nome_departamento: '',
        email_responsavel: '',
        status: '1',
      },
    }
  },

  methods: {
    salvarDepartamento(fecharModal) {
      this.load = true;
      this.$http.post("departamento/" + this.token, this.dados)
        .then(res => {
          this.load = false;
          this.$swal("Sucesso!","Departamento " + (this.editar ? "editado" : "cadastrado") + " com sucesso.");
          fecharModal(true);

        }, res => {
          console.log(res);
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
    },

    clear() {
      this.editar = false;
      this.menu = 1;
      this.dados = {
        nome_departamento: '',
        email_responsavel: '',
        status: '1',
      }
    }
  },

  created() {
    this.$parent.$on('salvar', (callback) => {
      this.salvarDepartamento(callback);
    });

    this.$parent.$on('loadDadosModal', (dados) => {

      if (dados) {
        this.editar = true;

        this.dados = {
          id_departamento: dados.id_departamento,
          nome_departamento: dados.nome_departamento,
          email_responsavel: dados.email_responsavel,
          status: dados.status,
        };

      } else {
        this.clear();
      }

      this.$helpers.focusrElement('nomeDepartamento');
    })
  },
}
</script>

<style scoped>

</style>
