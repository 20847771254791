<template>
  <div id="app">
    <div id="wrapper" v-if="!load">
      <side-bar v-if="!['Login', 'Home'].includes($route.name)"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <top-bar v-if="!['Login', 'Home'].includes($route.name)"/>
          <router-view/>
        </div>
        <bottom-bar v-if="$route.name !== 'Login'"/>
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center" style="height: 90vh;" v-if="load">
      <loading class="" text="Por favor aguarde"/>
    </div>

  </div>
</template>

<script>
  import SideBar from "./components/SideBar";
  import TopBar from "./components/TopBar";
  import BottomBar from "./components/BottomBar";
  import Loading from "./components/commons/Loading";

  export default {
    components: {Loading, TopBar, SideBar, BottomBar},
    data() {
      return {
        login: true,
        load: true,
        dados: {},
        token: '',
      }
    },

    methods: {
      autenticar() {
        this.$http.post('autenticar', {key: this.token})
          .then(res => {
            this.load = false;

            if (res.data.success) {
              this.dados = res.data.dados;
              this.$store.commit('setDataUser', res.data);

              if (this.$route.name === 'Login') {
                if (res.data.dados.id_funcao === '2') {
                  this.$router.push("/solicitacoes");
                } else {
                  this.$router.push("/dashboard")
                }
              }

              return;
            }

            localStorage.clear();
            this.$router.push("/");

          }, res => {
            this.load = false;
            localStorage.clear();
            this.$router.push("/");
            this.$swal(res.data.result, res.data.msg);
          });
      }
    },

    mounted() {
      const key = localStorage.getItem("key");
      if (key) {
        this.token = key;
        this.autenticar();

      } else {
        this.load = false;
        localStorage.clear();

        if (!['Login', 'Home'].includes(this.$route.name)) {
          if (this.$route.name === 'Solicitacao') {
            sessionStorage.setItem('redirect', this.$route.path);
            this.$router.push("/login");

          } else {
            this.$router.push("/")
          }
        }
      }
    },

    sockets: {
      connect() {
        if (localStorage.getItem('master')) {
          const socket_id = localStorage.getItem('token');
          // this.$socket.emit('empresa_connected', socket_id)
        }
      }
    },

    watch: {
      $route() {
        $(window).scrollTop(0);

        if ($(".sidebar").hasClass("toggled")) {
          $("body").toggleClass("sidebar-toggled");
          $(".sidebar").toggleClass("toggled");
          $('.sidebar .collapse').collapse('hide');
        }
      }
    },
  }
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>
