<template>
  <div>
    <loading v-show="load" text="Carregando os dados"/>
    <div v-show="!load">
      <h2 class="font-weight-bold">{{dados.nome_cliente}}</h2>
      <modal-menu :menus="menus" :selected="menu" @changeMenu="(m) => {this.menu = m}"/>
      <br>
      <div v-show="menu === 1">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="nomeCompleto">Nome Completo</label>
              <input type="text" class="form-control" id="nomeCompleto" v-model="dados.nome_cliente" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="telefone">Telefone</label>
              <input type="text" class="form-control" id="telefone" v-model="dados.telefone" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="email">E-mail</label>
              <input type="text" class="form-control" id="email" v-model="dados.email" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="cadastro">Data de Cadastro</label>
              <input type="text" class="form-control" id="cadastro" v-model="dados.created_at" disabled>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="endereco">Logradouro</label>
              <input type="text" class="form-control" id="endereco" v-model="dados.endereco" placeholder="Endereço" disabled>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="numero">Número</label>
              <input type="text" class="form-control" id="numero" v-model="dados.numero" placeholder="Número" disabled>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="cep">CEP</label>
              <input type="text" class="form-control" id="cep" v-model="dados.cep" placeholder="CEP" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="cidade">Cidade</label>
              <input type="text" class="form-control" id="cidade" v-model="dados.cidade" placeholder="Cidade" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="bairro">Bairro</label>
              <input type="text" class="form-control" id="bairro" v-model="dados.bairro" placeholder="Bairro" disabled>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="complemento">Complemento / Observações</label>
              <textarea type="text" class="form-control" id="complemento" v-model="dados.complemento" placeholder="Complemento / Observações" disabled></textarea>
            </div>
          </div>
        </div>
      </div>

      <div v-show="menu === 2">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="numeroPedidos">Nº de Pedidos</label>
              <input type="text" class="form-control" id="numeroPedidos" v-model="dados.qtd_pedido" disabled>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="ultimoPedido">Data do Último Pedido</label>
              <input type="text" class="form-control" id="ultimoPedido" disabled v-model="dados.ultimo_pedido">
            </div>
          </div>
        </div>
      </div>

      <div v-show="menu === 3">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold mb-1" for="senha">Senha</label>
              <input type="text" class="form-control" id="senha" placeholder="Senha" v-model="alterarSenha">
            </div>
          </div>
          <div class="col-md-2 mb-1">
            <label>&nbsp;</label>
            <button class="btn btn-danger btn-block mt-1" id="alterarSenha" @click="senhaCliente()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ModalMenu from "../../components/commons/ModalMenu";
  import Loading from "../../components/commons/Loading";

  export default {
    name: 'ClienteFormulario',
    components: {Loading, ModalMenu},
    data() {
      return {
        load: false,
        token: localStorage.getItem('key'),
        menus: [
          { nome: 'Dados', active: true },
          { nome: 'Pedidos', active: true },
          { nome: 'Resetar Senha', active: true },
        ],
        menu: 1,
        dados: {
          id_cliente: '',
          nome_cliente: '',
          telefone: '',
          email: '',
          cep: '37965-000',
          cidade: 1,
          bairro: '',
          endereco: '',
          numero: '',
          complemento: '',
          created_at: '',
        },
        alterarSenha: '',
      }
    },

    methods: {
      getCliente(id) {
        this.load = true;
        const url = 'clientes/' + id + '/' + this.token;
        this.$http.get(url + '?app_geral=' + (this.$route.name === 'ListaClientesAdm')).then(response => {
          if (response.data) {
            this.dados = response.data;
          }
          this.load = false;

        }, res => {
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
      },

      senhaCliente() {
        const dados = {
          id_cliente: this.dados.id_cliente,
          senha: this.alterarSenha
        };

        if (this.alterarSenha.trim().length < 6) {
          this.$swal("Atenção!","A senha deve conter no mínimo 6 caracteres");
          return;
        }

        this.load = true;
        this.$http.post('delivery/cliente/resetar-senha/' + this.token, dados)
          .then(res => {
            this.load = false;
            this.$swal("Sucesso!","Senha Editada com sucesso!");
            this.alterarSenha = '';

          }, res => {
            this.load = false;
            this.$swal(res.data.result,res.data.msg);

          });
      }
    },

    created() {
      this.$parent.$on('loadDadosModal', (id) => {
        this.menu = 1;
        this.getCliente(id)
      })
    },
  }
</script>

<style scoped>

</style>
