<template>
  <div class="container-fluid">
    <loading v-show="load" text="Carregando os dados"/>

    <div v-show="!load">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Usuários</h1>
        <button class="btn btn-primary btn-sm" @click="selUsuario()"><i class="fas fa-plus"></i> Cadastrar</button>
      </div>

      <div>
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="table-responsive" style="min-height: 100px">
              <table class="table">
                <thead class="border-bottom">
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th style="width: 200px">&nbsp;</th>
                  <th style="width: 100px">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr class="pointer" v-for="c in colaboradores" :key="c.id_usuario">
                  <td @click="selUsuario(c)">{{c.nome}}</td>
                  <td @click="selUsuario(c)">{{c.email}}</td>
                  <td class="text-center align-middle text-nowrap">
                    <div v-if="c.email !== usuario.email">
                      <button class="btn btn-sm mr-1" :class="c.fg_ativo === '1' ? 'btn-primary' : 'btn-light'" @click="toggleUsuario(c, '1')">Ativado</button>
                      <button class="btn btn-sm" :class="c.fg_ativo === '2' ? 'btn-primary' : 'btn-light'" @click="toggleUsuario(c, '2')">Desativado</button>
                    </div>
                  </td>
                  <td class="align-middle text-nowrap">
                    <button class="btn btn-primary btn-sm mr-2" title="Editar Produto" @click="selUsuario(c)"><i class="fas fa-pencil-alt"></i></button>
                    <button class="btn btn-dark btn-sm" title="Remover Produto" @click="deletarUsuario(c)"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal @fechar="buscarColaboradores()">
      <usuarios-formulario/>
    </modal>
  </div>
</template>

<script>
  import Modal from "../../components/Modal";
  import Loading from "../../components/commons/Loading";
  import UsuariosFormulario from "./UsuariosFormulario";

  export default {
    name: 'Usuarios',
    components: {UsuariosFormulario, Loading, Modal},
    data() {
      return {
        load: true,
        token: localStorage.getItem('key'),
        colaboradores: [],
      }
    },

    methods: {
      buscarColaboradores() {
        this.load = true;

        this.$http.get('usuarios/' + this.token, {params: this.pesquisa}).then(response => {
          this.load = false;

          if (response.data) {
            this.colaboradores = response.data;
          }

        }, res => {
          console.log(res);
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
      },

      toggleUsuario(c, status) {
        c.fg_ativo = status;
        this.$http.post('usuarios/' + this.token, c).then(res => {},
          res => {
          console.log(res);
          this.$swal(res.data.result ? res.data.result : "Atenção", res.data.msg ? res.data.msg : "Erro interno no servidor!");
        });
      },

      selUsuario(dados) {
        this.$emit('openModal', dados);
      },

      deletarUsuario(dados) {
        if (dados.email === this.usuario.email) {
          this.$swal("Você não pode se auto remover!");
          return;
        }

        this.$swal({
          text: "Deseja realmente deletar este usuário?",
          buttons: ["Cancelar", "Sim!"],
          dangerMode: true,
        }).then((result) => {
          if (result) {
            this.load = true;
            this.$http.post('usuarios/remover/' + this.token, dados).then(res => {
              this.load = false;
              this.$swal("Sucesso","Usuário removido com sucesso!");
              this.buscarColaboradores()

            }, res => {
              console.log(res);
              this.load = false;
              this.$swal(res.data.result ? res.data.result : "Atenção", res.data.msg ? res.data.msg : "Erro interno no servidor!");
            });
          }
        });
      }
    },

    mounted() {
      this.buscarColaboradores();
    },

    computed: {
      usuario() {
        return this.$store.state.dataUser
      }
    },
  }
</script>
