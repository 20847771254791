<template>
  <div class="container-fluid">
    <loading v-show="load"/>
    <div v-show="!load">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Aparência</h1>
        <div>
          <a href="javascript:" class="btn btn-primary" @click="salvarDados(1)">
            Salvar
          </a>
        </div>
      </div>

      <div class="card shadow mb-4">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import UploadImage from "../../components/UploadImage";
  import Loading from "../../components/commons/Loading";
  import Tooltip from "../../components/commons/Tooltip";
  export default {
    name: "Aparencia",
    components: {Tooltip, Loading, UploadImage},
    data() {
      return {
        token: localStorage.getItem('key'),
        load: false,
        imgToUpload: false,
        imgCapaToUpload: false,
        dados: {
          logo_delivery: '',
          descricao_delivery: '',
          theme_color: '',
        }
      }
    },

    methods: {
      changeImg() {},
      changeImgCapa() {},
    },

    mounted() {

    }
  }
</script>

<style scoped>

</style>
