import Vue from 'vue'
import helpers from './lecard-helper'

const plugin = {
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}

export default plugin;
