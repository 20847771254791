export default {
  ID() {
    return '_' + Math.random().toString(36).substr(2, 9);
  },
  focusrElement(id) {
    const el = document.getElementById(id);
    if (el) {
      setTimeout(function () {
        el.focus();
      }, 100);
    }
  }
};
