<script>
  import {Doughnut} from 'vue-chartjs'

  export default {
    name: "TipoPedido",
    extends: Doughnut,
    props: {dados:''},

    computed: {
      chartData() {
        return this.dados;
      },
    },

    methods: {
      renderizarGrafico() {
        const data = this.dados.map(v => { return v.qtd });
        const labels = this.dados.map(s => {
          switch (s.status) {
            case '1':
              return 'Aguardando';
            case '2':
              return 'Em análise';
            case '3':
              return 'Processando';
            case '4':
              return 'Finalizado';
            case '5':
              return 'Cancelado';
          }
        });

        const values = {
          labels,
          datasets: [{
            data,
            backgroundColor: ['#4e73df', '#1cc88a', '#f6c23e'],
            hoverBackgroundColor: ['#2e59d9', '#17a673', '#f6c23e'],
            hoverBorderColor: "rgba(234, 236, 244, 1)",
          }],
        };

        const options = {
          maintainAspectRatio: false,
          tooltips: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#858796",
            borderColor: '#dddfeb',
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            caretPadding: 10,
          },
          legend: {
            position: 'bottom',
            labels: { padding: 20 }
          },
          cutoutPercentage: 80,
        };

        this.renderChart(values, options)
      }
    },

    watch: {
      dados() {
        this.$data._chart.destroy();
        this.renderizarGrafico();
      }
    },

    mounted() {
      this.renderizarGrafico();
    },
  }
</script>
