<template>
  <div class="container-fluid">
    <loading v-show="load"/>
    <div v-show="!load">
      <div class="row justify-content-between align-items-right">
        <div class="col-sm-6 col-lg-2 mb-3">&nbsp;</div>

        <div class="col-sm-6 mb-3 text-right">
          <button class="btn btn-primary" @click="selDepartamento()"><i class="fas fa-plus"></i> Cadastrar</button>
        </div>
      </div>

      <div v-show="departamentos.length">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="table-responsive" style="min-height: 100px">
              <table class="table">
                <thead class="border-bottom">
                <tr>
                  <th>Departamentos</th>
                  <th style="width: 200px">&nbsp;</th>
                  <th style="width: 100px">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="d in departamentos">
                    <td @click="selDepartamento(d)" class="pointer">{{d.nome_departamento}}</td>
                    <td class="text-center align-middle text-nowrap">
                      <button class="btn btn-sm mr-1" :class="d.status === '1' ? 'btn-primary' : 'btn-light'" @click="toggleDepartamento(d, '1')">Ativado</button>
                      <button class="btn btn-sm" :class="d.status === '0' ? 'btn-primary' : 'btn-light'" @click="toggleDepartamento(d, '0')">Desativado</button>
                    </td>
                    <td class="align-middle text-nowrap">
                      <button class="btn btn-primary btn-sm mr-2" title="Editar Departamento" @click="selDepartamento(d)"><i class="fas fa-pencil-alt"></i></button>
                      <button class="btn btn-dark btn-sm" title="Remover Departamento" @click="deletarDepartamento(d)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!departamentos.length">
        <div class="d-flex justify-content-center align-items-center" style="padding-top: 10vh">
          <div class="text-center">
            <h2>Precisamos de um novo Departamento!</h2>
            <p>Cadastre um novo departamento.</p>
            <div class="mt-4">
              <button class="btn btn-primary" @click="selDepartamento()">Cadastrar Departamento</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal @fechar="getDepartamentos">
      <departamento-formulario/>
    </modal>
  </div>
</template>

<script>
  import draggable from "vuedraggable"
  import Modal from "../../components/Modal";
  import Loading from "../../components/commons/Loading";
  import DepartamentoFormulario from "./DepartamentoFormulario";

  export default {
    name: 'ListaDepartamentos',
    components: {draggable, DepartamentoFormulario, Loading, Modal},
    data() {
      return {
        load: false,
        departamentos: [],
        token: localStorage.getItem('key')
      }
    },
    methods: {
      getDepartamentos() {
        this.load = true;
        this.$http.get('departamento/' + this.token).then(response => {
          this.departamentos = response.data;
          this.load = false;

        }, res => {
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
      },

      selDepartamento(dados) {
        this.$emit('openModal', dados);
      },

      deletarDepartamento(d) {
        this.$swal({
          text: "Deseja realmente deletar este departamento?",
          buttons: ["Cancelar", "Sim!"],
          dangerMode: true,
        }).then((result) => {
          if (result) {
            this.load = true;
            this.$http.post('departamento/remover/' + this.token, d)
              .then(() => {
                this.$swal("Sucesso","Departamento deletado com sucesso!");
                this.getDepartamentos();

              }, res => {
                this.load = false;
                this.$swal(res.data.result,res.data.msg);
              });
          }
        });
      },

      toggleDepartamento(d, status) {
        d.status = status;
        this.$http.post("departamento/" + this.token, d)
          .then(res => {
          }, res => {
            this.$swal(res.data.result,res.data.msg);
          });
      }
    },

    mounted() {
      this.getDepartamentos();
    }
  }
</script>
