<template>
  <div class="container-fluid">
    <loading v-show="load" text="Carregando os dados"/>
    <div v-show="!load">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h1 class="h3 mb-0 text-gray-800">Cidadãos</h1>
        <!--<button class="btn btn-primary btn-sm" @click="gerarRelatorio()"><i class="fa fa-file-pdf"></i> Exportar</button>-->
      </div>
      <div>
        <div class="card shadow mb-4">
          <div class="card-body text-center text-md-left">
            <div class="row justify-content-between">
              <div class="col-md-10 mb-1">
                <label class="small font-weight-bold mb-0" for="referencia">Nome ou Telefone</label>
                <input type="text" id="referencia" class="form-control" placeholder="Nome / telefone / email" @keyup.enter="buscarCidadao(0)" v-model="pesquisa.termo">
              </div>
              <div class="col-md-2 mb-1">
                <label>&nbsp;</label>
                <button class="btn btn-primary btn-block" @click="buscarCidadao(0)">Pesquisar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-4">
          <div class="card-body">
            <div style="min-height: 100px">
              <div class="table-responsive">
                <table class="table">
                  <thead class="border-bottom">
                    <tr>
                      <th>Nome</th>
                      <th>Telefone</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="pointer" v-for="c in cidadaos">
                      <td>{{c.nome_cidadao}}</td>
                      <td class="d-none d-md-table-cell">{{c.telefone}}</td>
                      <td class="d-none d-md-table-cell">{{c.email}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-between">
                  <div class="col-md-3">
                    <button class="btn btn-outline-primary w-50" v-show="pesquisa.page > 0" @click="buscarCidadao(--pesquisa.page)">
                      <i class="fas fa-chevron-left"></i> Anterior
                    </button>
                  </div>
                  <div class="col-md-3 text-right">
                    <button class="btn btn-outline-primary w-50" @click="buscarCidadao(++pesquisa.page)" v-show="cidadaos.length >= 20">
                      Próximo <i class="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :preview="true">
      <!--<cidadao-formulario/>-->
    </modal>
  </div>
</template>

<script>
import Modal from "../../components/Modal";
import Loading from "../../components/commons/Loading";
import CidadaoFormulario from "./CidadaoFormulario";

export default {
  name: 'Cidadaos',
  components: {CidadaoFormulario, Loading, Modal},
  data() {
    return {
      token: localStorage.getItem('key'),
      cidadaos: [],
      load: false,

      pesquisa: {
        termo: ''
      },
    }
  },

  methods: {
    buscarCidadao() {
      this.load = true;
      this.$http.get('relatorio/cidadaos/' + this.token, {params: this.pesquisa}).then(response => {
        this.cidadaos = response.data;
        this.load = false;

      }, res => {
        this.load = false;
      });
    }
  },

  mounted() {
    this.buscarCidadao();
  }
}
</script>
