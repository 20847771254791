import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'

import Prefeitura from '../views/config/Prefeitura.vue'
import ListaUsuarios from '../views/config/ListaUsuarios.vue'
import Aparencia from '../views/config/Aparencia.vue'
import ListaDepartamentos from '../views/config/ListaDepartamentos.vue'

import ListaSolicitacoes from '../views/config/ListaSolicitacoes.vue'
import ListaCidade from '../views/adm/ListaCidade.vue'
import ListaPrefeitura from '../views/adm/ListaPrefeitura.vue'
import ListaServicos from '../views/adm/ListaServicos.vue'

import ListaInformativos from '../views/config/ListaInformativos.vue'

import Cidadaos from '../views/relatorio/Cidadaos.vue'

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/prefeitura',
    name: 'Prefeitura',
    component: Prefeitura
  },
  {
    path: '/usuarios',
    name: 'ListaUsuarios',
    component: ListaUsuarios
  },
  {
    path: '/aparencia',
    name: 'Aparencia',
    component: Aparencia
  },
  {
    path: '/departamentos',
    name: 'ListaDepartamentos',
    component: ListaDepartamentos
  },
  {
    path: '/solicitacoes',
    name: 'ListaSolicitacoes',
    component: ListaSolicitacoes
  },
  {
    path: '/solicitacoes/:id',
    name: 'Solicitacao',
    component: ListaSolicitacoes
  },
  {
    path: '/servicos',
    name: 'ListaServicos',
    component: ListaServicos
  },
  {
    path: '/informativos',
    name: 'ListaInformativos',
    component: ListaInformativos
  },
  {
    path: '/adm/cidades',
    name: 'ListaCidade',
    component: ListaCidade
  },
  {
    path: '/adm/prefeitura',
    name: 'ListaPrefeitura',
    component: ListaPrefeitura
  },
  {
    path: '/relatorio/cidadaos',
    name: 'Cidadoes',
    component: Cidadaos
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
