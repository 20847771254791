<template>
  <div class="upload" :class="{'inactive': !active}">
    <div class="upload__box text-decoration-none">
      <i class="fas fa-camera upload__icon text-white fa-2x" v-show="!img_preview"></i>
      <img :src="img_preview" v-show="img_preview"/>
      <div class="upload__label" v-if="active">
        <label class="btn btn-outline-light" :for="inputImage">
          <i class="fas fa-upload"></i> {{img_preview ? 'Trocar imagem' : 'Escolher imagem'}}
          <input type="file" :id="inputImage" accept="image/png,image/jpeg" :disabled="!active" @change="previewImg($event)" >
        </label>
      </div>
      <div class="upload__label upload__label--top" v-if="img_preview">
        <button class="btn btn-outline-light" @click="removeImg"><i class="fa fa-trash"></i> Remover imagem</button>
      </div>
    </div>
    <div class="small mt-3">
      <div>
        <b>Formatos:</b> JPEG, JPG e PNG
      </div>
      <div>
        <b>Peso máximo:</b> 1 MB
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadImage',
  props: ['active', 'image'],
  data() {
    return {
      img: '',
      img_preview: '',
      inputImage: this.$helpers.ID()
    }
  },

  methods: {
    previewImg(e) {
      if (e) {
        const img = e.target.files[0];
        if (!img || img.size > 1000000) {
          this.$swal("", "O tamanho máximo permitido de uma imagem é de 1MB!");
          return;
        }

        this.img = img;
        this.img_preview = URL.createObjectURL(this.img);

        const data = new FormData();
        data.append('file', this.img);
        this.$emit('changeImg', data);

      } else {
        this.clear();
        this.$emit('fecharModal');
      }
    },

    clear() {
      const el = document.getElementById(this.inputImage);
      if (el) {
        el.value = "";
      }

      this.img_preview = '';
      this.img = '';
    },

    removeImg() {
      this.clear();
      this.$emit('changeImg', null);
    }
  },

  watch: {
    image(value) {
      this.clear();
      this.img_preview = value
    }
  },

  created() {
    this.$parent.$on('clearImage', (value) => {
      this.clear();
      this.img_preview = value
    });
  }
}
</script>

<style scoped lang="scss">
  .upload {
    &__label {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0,0,0,0.4);
      text-align: center;
      padding: 12px 0 6px;
      border-radius: 0 0 8px 8px;

      &--top {
        bottom: auto;
        border-radius: 8px 8px 0 0;
        top: 0;
      }
    }

    &__box {
      width: 100%;
      height: 200px;
      border-radius: 8px;
      background-color: var(--gray);
      position: relative;

      input { display: none; }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        border-radius: 8px;
      }

      &:hover {
        .upload__label {
          display: block;
        }
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0 auto;
      transform: translate(-50%, -50%);
    }
  }

  .inactive {
    opacity: 0.7;
    .upload__box {
      cursor: not-allowed;
    }
  }
</style>
