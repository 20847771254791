<template>
  <div class="modal-lecard">
    <form @submit.prevent="salvarModal">
      <div class="modal__inner">
        <div class="modal-lecard__container">
          <div class="container">
            <slot></slot>
          </div>
        </div>
        <div class="modal-lecard__footer border-top">
          <button type="button" class="btn btn-outline-primary" @click="closeModal">{{preview ? 'Fechar' : 'Cancelar'}}</button>
          <button type="submit" class="btn btn-primary ml-3" v-show="!preview">Salvar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'Modal',
    props: ['preview','cancelar'],
    data() {
      return {
        modalOpen: true
      }
    },
    methods: {
      closeModal() {
        $('body').removeClass('modal-open');
        $('.modal-lecard').removeClass('modal-lecard__open');
        // this.$emit('fechar');
      },

      openModal() {
        $('body').addClass('modal-open');
        $('.modal-lecard').addClass('modal-lecard__open');
      },

      salvarModal() {
        this.$emit('salvar', (fechar) => {
          this.$emit('fechar');

          if (fechar) {
            this.closeModal();
          }
        });
      }
    },

    created() {
      this.$parent.$on('openModal', (id) => {
        this.openModal();
        this.$emit('loadDadosModal', id);
      });

      this.$parent.$on('closeModal', () => {
        this.closeModal();
      });
    }
  }
</script>

<style scoped>
  .modal-lecard {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    background: rgba(0,0,0,.7);
    transition: opacity .25s ease;
    z-index: 999;
  }

  .modal-lecard__open {
   opacity: 1;
    visibility: visible;
  }

  .modal__inner {
    transition: top .25s ease;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 55%;
    margin: auto;
    overflow: auto;
    background: #fff;
    height: 100%;
  }

  .modal-lecard__container {
    height: 90%;
    padding-top: 16px;
    overflow: auto;
  }

  .modal-lecard__footer {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 16px;
  }

  @media screen and (max-width: 768px) {
    .modal__inner {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
</style>
