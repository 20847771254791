<template>
  <div class="container-fluid">
    <loading v-show="load"/>
    <div v-show="!load">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Cidades</h1>
        <button class="btn btn-primary" @click="selCidade()"><i class="fas fa-plus"></i> Cadastrar</button>
      </div>

      <div class="card">
        <div class="card-body text-center text-md-left">
          <div class="table-responsive" style="min-height: 100px">
            <table class="table">
              <thead class="border-bottom">
              <tr>
                <th>Cidade</th>
                <th>Estado</th>
                <th>Sigla</th>
              </tr>
              </thead>
              <tbody>
                <tr class="pointer" v-for="d in dados" @click="selCidade(d)">
                  <td>{{d.nome_cidade}}</td>
                  <td>{{d.estado}}</td>
                  <td>{{d.sigla_estado}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <modal @fechar="buscarDadosCidade()">
      <cidade-formulario/>
    </modal>
  </div>
</template>

<script>
  import Loading from "../../components/commons/Loading";
  import Modal from "../../components/Modal";
  import CidadeFormulario from "./CidadeFormulario"

  export default {
    name: 'ListaCidades',
    components: { Loading, Modal, CidadeFormulario },
    data() {
      return {
        token: localStorage.getItem('key'),
        load: true,
        dados: {
          cep: '',
          delivery: '',
          estado: '',
          lat_cidade: '',
          long_cidade: '',
          nome_cidade: '',
          raio_cidade: '',
          sigla_estado: '',
          id_cidade: ''
        }
      }
    },

    methods: {
      selCidade(dados) {
        this.$emit('openModal', dados);
      },

      buscarDadosCidade() {
        this.$http.get('cidades/' + this.token)
          .then(response => {
            this.load = false;
            this.dados = response.data;

          }, res => {
            this.load = false;
            this.$swal(res.data.result, res.data.msg);
          })
      }

    },

    mounted() {
      this.buscarDadosCidade();
    }
  }
</script>
