<template>
  <div>
    <loading v-show="load" text="Carregando os dados"/>
    <div v-show="!load">
      <h2 class="font-weight-bold">{{dados.nome_cidade ? dados.nome_cidade : 'Nome Cidade'}} {{dados.sigla_estado ? ' (' + dados.sigla_estado + ')' : ''}}</h2>
      <div class="card">
        <div class="card-body text-center text-md-left">
          <div class="row">
            <div class="col-md-6">
              <label class="m-0" for="nome_cidade"><b>Nome Cidade</b></label>
              <input type="text" id="nome_cidade" class="form-control" placeholder="Informe o nome da cidade" v-model="dados.nome_cidade" required>
            </div>
            <div class="col-md-3">
              <label class="m-0" for="estado"><b>Estado</b></label>
              <select name="estado" id="estado" class="form-control" v-model="dados.estado" required>
                <option value="">Default</option>
                <option value="Acre">Acre</option>
                <option value="Alagoas">Alagoas</option>
                <option value="Amapá">Amapá</option>
                <option value="Amazonas">Amazonas</option>
                <option value="Bahia">Bahia</option>
                <option value="Ceará">Ceará</option>
                <option value="Destrito Federal">Destrito Federal</option>
                <option value="Espírito Santo">Espírito Santo</option>
                <option value="Goiás">Goiás</option>
                <option value="Maranhão">Maranhão</option>
                <option value="Mato Grosso">Mato Grosso</option>
                <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                <option value="Minas Gerais">Minas Gerais</option>
                <option value="Pará">Pará</option>
                <option value="Paraíba">Paraíba</option>
                <option value="Paraná">Paraná</option>
                <option value="Pernambuco">Pernambuco</option>
                <option value="Piauí">Piauí</option>
                <option value="Rio de Janeiro">Rio de Janeiro</option>
                <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                <option value="Rondônia">Rondônia</option>
                <option value="Roraima">Roraima</option>
                <option value="Santa Catarina">Santa Catarina</option>
                <option value="São Paulo">São Paulo</option>
                <option value="Sergipe">Sergipe</option>
                <option value="Tocantins">Tocantins</option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="m-0" for="sigla"><b>Sigla</b></label>
              <select name="sigla" id="sigla" class="form-control" v-model="dados.sigla_estado" required>
                <option value="">Default</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
            </div>
            <div class="col-md-4 mt-2">
              <label class="m-0" for="cep"><b>CEP</b></label>
              <the-mask typeof="number" :mask="['#####-###']" id="cep" class="form-control" placeholder="Informe o CEP" v-model="dados.cep"/>
            </div>
            <div class="col-md-4 mt-2">
              <label class="m-0" for="latitude"><b>Latitude</b></label>
              <input class="form-control" type="text" id="latitude" placeholder="Informe a Latitude" v-model="dados.lat_cidade" required>
            </div>
            <div class="col-md-4 mt-2">
              <label class="m-0" for="longitude"><b>Longitude</b></label>
              <input class="form-control" type="text" id="longitude" placeholder="Informe a Longitude" v-model="dados.long_cidade" required>
            </div>
            <div class="col-md-4 mt-2">
              <label class="m-0" for="raio"><b>Raio</b></label>
              <input class="form-control" type="text" id="raio" placeholder="Informe o Raio" v-model="dados.raio_cidade" required>
            </div>
            <div class="col-md-4 mt-2">
              <label class="m-0" for="ibge"><b>IBGE</b></label>
              <input class="form-control" type="text" id="ibge" placeholder="Informe o IBGE" v-model="dados.ibge" required>
            </div>
            <div class="col-md-4 mt-2">
              <label class="m-0" for="delivery"><b>APP Geral</b></label>
              <select class="form-control" name="delivery" id="delivery" v-model="dados.delivery" required>
                <option value="">Default</option>
                <option value="1">Habilitado</option>
                <option value="0">Desabilitado</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMenu from "../../components/commons/ModalMenu";
import Loading from "../../components/commons/Loading";

export default {
  name: 'CidadeFormulario',
  components: {Loading , ModalMenu},
  data() {
    return {
      token: localStorage.getItem('key'),
      load: false,
      editar: false,
      dados: {
        cep: '',
        delivery: '',
        estado: '',
        lat_cidade: '',
        long_cidade: '',
        nome_cidade: '',
        raio_cidade: '',
        ibge: '',
        sigla_estado: '',
        id_cidade: ''
      }
    }
  },

  methods: {
    salvarDados(fecharModal) {
      this.load = true;
      this.$http.post('cidades/' + this.token, this.dados)
        .then(res => {
          this.load = false;
          this.$swal("Sucesso!", (this.editar ? 'Informações editadas com sucesso' : 'Cidade cadastrada com sucesso'));
          fecharModal(true);

        }, res => {
          console.log(res);
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
    }
  },

  created() {
    this.$parent.$on('loadDadosModal', (dados) => {

      if (dados) {
        this.editar = true;
        this.dados = dados;

      } else {
        this.$helpers.focusrElement('nome_cidade');

        this.editar = false;
        this.load = false;
        this.dados = {
          cep: '',
          delivery: '',
          estado: '',
          lat_cidade: '',
          long_cidade: '',
          nome_cidade: '',
          raio_cidade: '',
          sigla_estado: '',
          id_cidade: ''
        }
      }

    });

    this.$parent.$on('salvar', (callback) => {
      this.$emit('fechar');
      this.salvarDados(callback);
    })
  },
}
</script>

<style scoped>

</style>
