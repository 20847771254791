<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <button class="btn btn-link d-md-none rounded-circle mr-3" @click="toggleSidebar()">
      <i class="fa fa-bars"></i>
    </button>
    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="text-right">
            <span class="text-gray-800 font-weight-bold d-block small" style="line-height: 16px">
              {{dados.nome}}
              <div class="font-weight-normal" v-if="dados.nome_departamento">{{dados.nome_departamento}}</div>
            </span>
            <span class="text-gray-600 d-block small" style="line-height: 16px">{{dados.nome_fantasia}}</span>
          </div>
          <img class="img-profile rounded-circle ml-3" :src="dados.logo" v-if="dados.logo">
          <img class="img-profile rounded-circle ml-3" src="../assets/logo.png" v-else>
        </a>
        <!-- Dropdown - User Information -->
        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
          <router-link to="/prefeitura" class="dropdown-item" v-if="adm">
            <i class="fas fa-building fa-sm fa-fw mr-2 text-gray-400"></i>
            Prefeitura
          </router-link>
          <div v-if="adm" class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:" @click="logout">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Sair
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      token: ''
    }
  },

  methods: {
    logout() {
      this.$swal({
        text: "Deseja sair do Portal Solicita-e?",
        buttons: ["Cancelar", "Sim"],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          this.$store.state.dataUser = {};
          localStorage.clear();
          this.$router.push('/');
        }
      });
    },

    toggleSidebar() {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide');
      }
    },

    acessarEmpresa(id) {
      this.load = true;
      this.$http.post("adm/acesso/" + this.token, {id_empresa: id})
        .then(res => {
          window.location.href = '/';

        }, res => {
          console.log(res);
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
    }
  },

  created() {
    this.token = localStorage.getItem('key');
  },

  computed: {
    dados() {
      return this.$store.state.dataUser
    },

    adm() {
      return this.$store.state.dataUser.id_funcao === '1'
    }
  }
}
</script>
