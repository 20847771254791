import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dataUser: {
      token: '',
      nome: '',
      nome_prefeitura: '',
      email: '',
      logo: '',
      master: '',
      id_funcao: '',
    }
  },
  mutations: {
    setDataUser: (state, data) => {
      const master = data.dados.master === '1';
      state.dataUser = {
        ...data.dados,
        logo: data.dados.url_imagem,
        master,
      };

      if (master) {
        localStorage.setItem('master', 'true');
      } else {
        localStorage.removeItem('master');
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
