<template>
  <div class="container">
    <div style="height: 10vh">&nbsp;</div>
    <div class="row justify-content-center">
      <div class="col-xl-11 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-sm my-2">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div class="text-center">
                    <div class="mb-3">
                      <img src="../assets/logo.png" alt="Logo Solcitae" style="width: 100px">
                    </div>
                    <h1 class="h4 text-gray-900 mb-1">Portal Solicita-e</h1>
                    <p class="mb-4">Para entrar use seu email e senha</p>
                  </div>
                  <form @submit.prevent="logar">
                    <div class="form-group">
                      <input type="email" class="form-control" aria-describedby="emailHelp"
                             placeholder="Insira seu email" v-model="dados.email" required autocomplete="username">
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control" v-model="dados.senha" placeholder="Senha" required autocomplete="password">
                    </div>
                    <button class="btn btn-primary btn-block" :disabled="load">
                      {{load ? 'Por favor aguarde..' : 'Login'}}
                    </button>
                    <br>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from "../components/commons/Loading";
  export default {
    name: 'Login',
    components: {Loading},
    data() {
      return {
        load: false,
        tokenEmpresa: '',
        dados: {
          email: '',
          senha: ''
        },
      }
    },

    methods: {
      logar() {
        this.load = true;
        this.$http.post('autenticar', this.dados)
          .then(res => {
            localStorage.clear();
            const data = res.data;

            if (data.success) {
              localStorage.setItem("key", data.token);
              localStorage.setItem("token", data.empresa);
              this.$store.commit('setDataUser', data);

              setTimeout(() => {
                if (sessionStorage.getItem('redirect')) {
                  this.$router.push(sessionStorage.getItem('redirect'));
                  sessionStorage.clear();

                } else if (res.data.dados.id_funcao === '2') {
                  this.$router.push("/solicitacoes");

                } else {
                  this.$router.push("/dashboard")
                }

              }, 500);

            } else {
              this.$swal("", "Você não tem acesso nesta funcionalidade! Consulte o gestor do seu estabelecimento para saber mais.");
              this.load = false;
            }

          }, res => {
            console.log(res);
            this.load = false;
            this.$swal(res.data.result, res.data.msg);
          });
      }
    },

    mounted() {
      if (localStorage.getItem('key')) {
        this.$router.push('/dashboard')
      }
    }
  }
</script>
