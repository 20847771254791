import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResource from 'vue-resource'
import VueSwal from 'vue-swal'
import VueSocketIO from 'vue-socket.io';
import helpers from './helpers'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import VueConfetti from 'vue-confetti'

import './assets/css/sb-admin-2.css'
import './assets/js/sb-admin-2'
import '@fortawesome/fontawesome-free/css/all.css'
import 'leaflet/dist/leaflet.css';
import './assets/fonts/flaticon/flaticon.css';
import './assets/css/main.scss'

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueSwal);
Vue.use(helpers);
Vue.use(VueTheMask);
Vue.use(VueConfetti);
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false
});

Vue.http.options.emulateJSON = true;
Vue.http.options.emulateHTTP = true;
Vue.http.options.root = process.env.VUE_APP_BASE_SERVER + 'api/';

Vue.filter('money', function (value) {
  if (value === undefined || value === false) {
    return value;
  }

  value = parseFloat(value);
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('phone', (phone) => {
  if (phone) {
    if (phone.length === 10) {
      return phone.replace(/[^0-9]/g, '')
        .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');

    } else if (phone.length === 11) {
      return phone.replace(/[^0-9]/g, '')
        .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
  }

  return phone
});

Vue.filter('status', (s) => {
  switch (s) {
    case '1':
      return 'Aguardando';
    case '2':
      return 'Em análise';
    case '3':
      return 'Processando';
    case '4':
      return 'Finalizado';
    case '5':
      return 'Cancelado';
  }
});

Vue.filter('tipo', (s) => {
  return s === '1' ? 'Serviço' : 'Ouvidoria'
});

Date.prototype.toDateInputValue = (function() {
  let local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0,10);
});

if (process.env.VUE_APP_BASE_SOCKET) {
  const base_socket = process.env.VUE_APP_BASE_SOCKET;
  Vue.use(new VueSocketIO({
    debug: false,
    connection: base_socket
  }));
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
