<template>
  <div>
    <loading v-show="load" text="Carregando os dados"/>
    <div v-show="!load">
      <h2 class="font-weight-bold">{{dados.nome ? dados.nome : 'Usuário'}}</h2>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold mb-0" for="nomeCompleto">Nome</label>
            <input type="text" class="form-control" placeholder="Nome do colaborador" id="nomeCompleto" v-model="dados.nome" required>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold mb-0" for="funcao">Função</label>
            <select id="funcao" class="form-control" v-model="dados.id_funcao" required>
              <option v-for="f in funcoes" :key="f.id_funcao" :value="f.id_funcao">{{f.nome_funcao}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold mb-0" for="departamento">Departamento</label>
            <select id="departamento" class="form-control" v-model="dados.id_departamento" required>
              <option :value="null">Default</option>
              <option :value="d.id_departamento" v-for="d in departamentos">{{d.nome_departamento}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" >
          <div class="form-group">
            <label class="font-weight-bold mb-0" for="email">E-mail</label>
            <input type="email" class="form-control" placeholder="E-mail do colaborador" id="email" v-model="dados.email" autocomplete="username"  required>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold mb-0" for="senha">Senha</label>
            <input type="password" class="form-control" autocomplete="new-password" placeholder="Senha do colaborador" id="senha" v-model="senha" :disabled="!alterarSenha" minlength="6">
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="alterarSenha"
                   @change="alterarSenha = !alterarSenha"
                   :checked="alterarSenha">
            <label class="form-check-label" for="alterarSenha">Alterar Senha</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/commons/Loading";

export default {
  name: 'UsuariosFormulario',
  components: {Loading},
  data() {
    return {
      load: false,
      editar: false,
      token: localStorage.getItem('key'),
      senha: '',
      alterarSenha: false,
      departamentos: [],
      funcoes: [
        { id_funcao: '1', nome_funcao: 'Administrador' },
        { id_funcao: '2', nome_funcao: 'Servidor' },
      ],

      dados: {
        nome: '',
        email: '',
        id_funcao: 0,
        id_departamento: null,
        fg_ativo: '1',
      },
    }
  },

  methods: {
    editarOrInsert(fecharModal) {
      if (this.alterarSenha) {
        if (this.senha.trim().length < 6) {
          this.$swal("Atenção!","A senha deve conter no mínimo 6 caracteres");
          return;

        } else {
          this.dados.senha = this.senha;
        }

      } else {
        delete this.dados.senha;
      }

      this.load = true;
      this.$http.post('usuarios/' + this.token, this.dados)
        .then(res => {
            this.load = false;
            this.$swal("Sucesso", (this.editar ? 'Usuário editado com sucesso!' : 'O usuário foi cadastrado com sucesso!'));
            fecharModal(true);
          },

          res => {
            this.load = false;
            console.log(res);
            this.$swal(res.data.result ? res.data.result : "Atenção", res.data.msg ? res.data.msg : "Erro interno no servidor!");
          });
    },

    getDepartamentos() {
      this.load = true;
      this.$http.get('departamento/' + this.token).then(response => {
        this.departamentos = response.data;
        this.load = false;

      }, res => {
        this.load = false;
        this.$swal(res.data.result,res.data.msg);
      });
    },

    clear() {
      this.editar = false;
      this.alterarSenha = false;
      this.senha = '';
      this.dados = {
        nome: '',
        email: '',
        id_funcao: '2',
        id_departamento: null,
        fg_ativo: '1',
      }
    },
  },

  mounted() {
    this.getDepartamentos();
  },

  created() {
    this.$parent.$on('salvar', (fecharModal) => {
      this.editarOrInsert(fecharModal);
    });

    this.$parent.$on('loadDadosModal', (dados) => {

      if (dados) {
        this.alterarSenha = false;
        this.dados = {
          id_usuario: dados.id_usuario,
          nome: dados.nome,
          email: dados.email,
          id_funcao: dados.id_funcao,
          id_departamento: dados.id_departamento,
          fg_ativo: dados.fg_ativo
        }

      } else {
        this.clear();
        this.alterarSenha = true;
      }
    })
  }
}
</script>

<style scoped>

</style>
