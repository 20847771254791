<template>
  <div>
    <loading v-show="load" :text="load"/>
    <div v-show="!load">
      <h2 class="font-weight-bold">
        {{dados.nome_servico}}
        <span v-show="dados.nome_servico.trim() === ''">Serviço</span>
      </h2>
      <modal-menu :menus="menus" :selected="menu" @changeMenu="(m) => {this.menu = m}"/>
      <div v-show="menu === 1">
        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="nomeServico">Nome do serviço</label>
              <input type="text" class="form-control" id="nomeServico" v-model="dados.nome_servico" placeholder="Ex: Remoção de árvores">
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="departamento">Departamento</label>
              <select id="departamento" class="form-control" v-model="dados.id_departamento" v-if="departamentos">
                <option v-for="d in departamentos" :value="d.id_departamento">{{d.nome_departamento}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="tipo">Tipo Solicitação</label>
              <select id="tipo" class="form-control" v-model="dados.tipo_solicitacao">
                <option value="1">Serviços</option>
                <option value="2">Ouvidoria</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-show="dados.tipo_solicitacao === '1'">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="local">Local Obrigatório</label>
              <select id="local" class="form-control" v-model="dados.local">
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-show="dados.tipo_solicitacao === '1'">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="arquivo">Arquivos Obrigatório</label>
              <select id="arquivo" class="form-control" v-model="dados.arquivo">
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="descricaoServico">Descrição</label>
              <textarea class="form-control" id="descricaoServico" v-model="dados.descricao"
                        placeholder="Ex: Remoção de galhos quebrados" rows="4">
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import ModalMenu from "../../components/commons/ModalMenu";
  import Loading from "../../components/commons/Loading";
  import Tooltip from "../../components/commons/Tooltip";

  export default {
    name: 'ServicosFormulario',
    components: {Tooltip, Loading, ModalMenu},
    props: ['departamentos'],
    data() {
      return {
        load: false,
        token: localStorage.getItem('key'),
        menus: [
          { nome: 'Dados', active: true }
        ],
        menu: 1,

        editar: true,
        dados: {
          nome_servico: '',
          descricao: '',
          id_departamento: '1',
          tipo_solicitacao: '1',
          local: '0',
          arquivo: '0'
        }
      }
    },

    methods: {
      salvarServico(fecharModal) {
        this.load = true;
        this.$http.post("servicos/" + this.token, this.dados)
          .then(res => {
            this.load = false;
            this.$swal("Sucesso!","Serviço " + (this.editar ? "editado" : "cadastrado") + " com sucesso.");
            fecharModal(true);

          }, res => {
            console.log(res);
            this.load = false;
            this.$swal(res.data.result,res.data.msg);
          });
      },

      clear() {
        this.menu = 1;
        this.dados = {
          nome_servico: '',
          descricao: '',
          id_departamento: '1',
          tipo_solicitacao: '1',
          local: '0',
          arquivo: '0',
        };
      }
    },

    created() {
      this.$parent.$on('salvar', (callback) => {
        this.salvarServico(callback);
      });

      this.$parent.$on('loadDadosModal', (dados) => {
        this.clear();

        if (dados.id_servico) {
          this.editar = true;
          this.dados = {
            id_servico: dados.id_servico,
            nome_servico: dados.nome_servico,
            descricao: dados.descricao,
            id_departamento: dados.id_departamento,
            tipo_solicitacao: dados.tipo_solicitacao,
            local: dados.local,
            arquivo: dados.arquivo,
            fg_ativo: dados.fg_ativo
          }

        } else {
          this.dados.tipo_solicitacao = dados.tipo_solicitacao;
          this.editar = false;
        }
      })
    },

    mounted() {
    }
  }
</script>

<style scoped>
</style>
