<script>
  import {Bar} from 'vue-chartjs'

  export default {
    name: "TipoVendas",
    extends: Bar,
    props: {departamentos: ''},

    computed: {
      chartData() {
        return this.departamentos;
      },
    },

    methods: {
      renderizarGrafico() {

        const dados = {
          qtd: [],
          labels: [],
        };

        dados.qtd = this.chartData.map(v => { return v.qtd });
        dados.labels = this.chartData.map(d => { return d.nome_departamento });

        const data = {
          labels: dados.labels,
          datasets: [{
            borderWidth: 1,
            barThickness: 45,
            backgroundColor: ['#4e73df', '#ff0000', '#ff6400', '#f6c23e', '#1cc88a'],
            hoverBackgroundColor: ['#2e59d9', '#ff0000', '#ff6400', '#f6c23e', '#17a673'],
            hoverBorderColor: "rgba(234, 236, 244, 1)",
            data: dados.qtd
          }]
        };

        const options = {
          maintainAspectRatio: false,
          tooltips: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#858796",
            titleMarginBottom: 10,
            titleFontColor: '#6e707e',
            titleFontSize: 14,
            borderColor: '#dddfeb',
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            intersect: false,
            mode: 'index',
            caretPadding: 10,
            callbacks: {
              label: function(tooltipItem, chart) {
                const datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                return datasetLabel + tooltipItem.yLabel;
              }
            }
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              time: {
                unit: 'date'
              },
              gridLines: {
                display: false,
                drawBorder: false
              },
              ticks: {
                maxTicksLimit: 7
              }
            }],
            yAxes: [{
              ticks: {
                maxTicksLimit: 5,
                padding: 10,
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                  return value
                }
              },
              gridLines: {
                color: "rgb(234, 236, 244)",
                zeroLineColor: "rgb(234, 236, 244)",
                drawBorder: false,
                borderDash: [2],
                zeroLineBorderDash: [2]
              }
            }],
          },
          cutoutPercentage: 80,
        };

        this.renderChart(data, options)
      }
    },

    watch: {
      departamentos() {
        this.renderizarGrafico();
      }
    },

    mounted() {
      this.renderizarGrafico();
    },
  }
</script>
