<template>
  <div class="container-fluid">
    <loading v-show="load"/>
    <div v-show="!load">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Prefeituras</h1>
        <button class="btn btn-primary" @click="selCidade()"><i class="fas fa-plus"></i> Cadastrar</button>
      </div>

      <div class="card">
        <div class="card-body text-center text-md-left">
          <div class="table-responsive" style="min-height: 100px">
            <table class="table">
              <thead class="border-bottom">
              <tr>
                <th>Nome</th>
                <th>Estado</th>
                <th>Sigla</th>
              </tr>
              </thead>
              <tbody>
                <tr class="pointer" v-for="d in dados" @click="selCidade(d)">
                  <td>{{d.nome_cidade}}</td>
                  <td>{{d.estado}}</td>
                  <td>{{d.sigla_estado}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <modal>
      <prefeitura-formulario/>
    </modal>
  </div>
</template>

<script>
  import Loading from "../../components/commons/Loading";
  import Modal from "../../components/Modal";
  import PrefeituraFormulario from "./PrefeituraFormulario"

  export default {
    name: 'ListaPrefeituras',
    components: { Loading, Modal, PrefeituraFormulario },
    data() {
      return {
        token: localStorage.getItem('key'),
        load: false,
        dados: [
          {
            estado: 'Minas Gerais',
            nome_cidade: 'Prefeitura Municipal de São Tomás de Aquino',
            sigla_estado: 'MG',
          }
        ]
      }
    },

    methods: {
      selCidade(dados) {
        this.$emit('openModal', dados);
      },

    },

    mounted() {

    }
  }
</script>
