<template>
  <ul class="navbar-nav bg-primary sidebar sidebar-dark accordion" id="accordionSidebar" :style="adm ? 'padding-bottom: 50%' : 'padding-bottom: 82%'">
    <router-link to="/dashboard" class="sidebar-brand d-flex align-items-center justify-content-center">
      <div class="sidebar-brand-icon">
        <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/idv/logo-horizontal2.png" alt="Logo Solicitae" style="width: 86px" id="logoMenuLecard">
      </div>
    </router-link>
    <hr class="sidebar-divider my-0 mb-2">

    <li class="nav-item" v-if="adm">
      <router-link class="nav-link" to="/dashboard">
        <i class="fl flaticon-home"></i>
        <span>Home</span></router-link>
    </li>

    <li class="nav-item">
      <router-link to="/solicitacoes" class="nav-link collapsed">
        <i class="fl flaticon-form"></i>
        <span>Solicitações</span>
      </router-link>
    </li>

    <li class="nav-item" v-if="adm">
      <router-link to="/departamentos" class="nav-link collapsed">
        <i class="fl flaticon-pages"></i>
        <span>Departamentos</span>
      </router-link>
    </li>

    <li class="nav-item" v-if="adm">
      <router-link to="/servicos" class="nav-link collapsed">
        <i class="fl flaticon-pages"></i>
        <span>Serviços</span>
      </router-link>
    </li>

    <li class="nav-item" v-if="adm">
      <router-link to="/informativos" class="nav-link collapsed">
        <i class="fl flaticon-news"></i>
        <span>Informativos</span>
      </router-link>
    </li>

    <li class="nav-item" v-if="adm">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseInvoice" aria-expanded="true" aria-controls="collapseInvoice">
        <i class="fl flaticon-spreadsheet-app"></i>
        <span>Relatórios</span>
      </a>
      <div id="collapseInvoice" class="collapse" aria-labelledby="headingUtilities" data-parent="#collapseInvoice">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Selecione:</h6>
          <router-link class="collapse-item" to="/relatorio/cidadaos">Cidadãos</router-link>
        </div>
      </div>
    </li>

    <li class="nav-item" v-if="adm">
      <router-link to="/prefeitura" class="nav-link collapsed">
        <i class="fl flaticon-house"></i>
        <span>Prefeitura</span>
      </router-link>
    </li>

    <li class="nav-item" v-if="adm">
      <router-link to="/usuarios" class="nav-link collapsed">
        <i class="fl flaticon-user"></i>
        <span>Usuários</span>
      </router-link>
    </li>

    <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAjuda" aria-expanded="true" aria-controls="collapseDelivery">
        <i class="fl flaticon-lifebuoy"></i>
        <span>Ajuda</span>
      </a>
      <div id="collapseAjuda" class="collapse" aria-labelledby="collapseAjuda" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Selecione:</h6>
          <a href="https://solicitae.tawk.help/" target="_blank" class="collapse-item">Wiki</a>
          <a href="javascript:" onclick="Tawk_API.maximize()" class="collapse-item">Suporte</a>
        </div>
      </div>
    </li>

    <hr class="sidebar-divider">

    <li class="nav-item" v-if="!master">
      <a href="javascript:" class="nav-link collapsed" @click="logout">
        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
        <span>Sair</span>
      </a>
    </li>

    <li class="nav-item" v-if="master">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAdm" aria-expanded="true" aria-controls="collapseUtilities">
        <i class="fl flaticon-settings"></i>
        <span>Administrativo</span>
      </a>
      <div id="collapseAdm" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Selecione:</h6>
          <!--<router-link class="collapse-item" to="/adm/cidades">Cidades</router-link>-->
          <router-link class="collapse-item" to="/adm/prefeitura">Prefeituras</router-link>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    msg: String
  },

  methods: {
    logout() {
      this.$swal({
        text: "Deseja sair do Portal Solicita-e?",
        buttons: ["Cancelar", "Sim"],
        dangerMode: true,
      }).then((res) => {
        if (res) {
          this.$store.state.dataUser = {};
          localStorage.clear();
          this.$router.push('/');
        }
      });
    }
  },

  computed: {
    user() {
      return this.$store.state.dataUser
    },

    master() {
      return this.$store.state.dataUser.master
    },

    adm() {
      return this.$store.state.dataUser.id_funcao === '1'
    }
  }
}
</script>
