<template>
  <div>
    <loading v-show="load" text="Carregando os dados"/>
    <div v-show="!load">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="font-weight-bold">
          {{dados.titulo}}
          <span v-if="!dados.titulo">Horários coleta de Lixo</span>
        </h2>
        <div v-if="dados.id_artigo">
          <a :href="'https://saotomasdigital.com.br/informativos/artigo/' + dados.id_artigo" target="_blank" class="btn btn-outline-primary btn-sm text-nowrap">Pré Visualizar</a>
        </div>
      </div>
      <hr>
      <div>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="mb-0" for="titulo"><b>Título</b> ({{dados.titulo.length}} de 72)</label>
              <input type="text" class="form-control" id="titulo" placeholder="Ex: Horários coleta de Lixo" v-model="dados.titulo" maxlength="72">
            </div>
          </div>
          <div class="col-md-4" v-if="departamentos">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="departamento">Departamento</label>
              <select class="form-control" v-model="dados.id_departamento" id="departamento">
                <option :value="null">Selecione</option>
                <option :value="d.id_departamento" v-for="d in departamentos">{{ d.nome_departamento }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="mb-0" for="descricao"><b>Descrição Curta</b> ({{dados.descricao.length}} de 256)</label>
              <textarea class="form-control" id="descricao" placeholder="Informe uma descrição curta" v-model="dados.descricao" rows="3" maxlength="256" style="resize: none"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold mb-0" for="texto">Artigo</label>
              <textarea class="form-control" id="texto" placeholder="Escreva aqui o texto do seu informativo" v-model="dados.texto" rows="12" style="resize: vertical"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMenu from "../../components/commons/ModalMenu";
import UploadImage from "../../components/UploadImage";
import Loading from "../../components/commons/Loading";

export default {
  name: 'DepartamentoFormulario',
  components: {Loading, UploadImage, ModalMenu},
  props: ['departamentos'],
  data() {
    return {
      load: false,
      token: localStorage.getItem('key'),
      editar: false,
      dados: {
        titulo: '',
        descricao: '',
        texto: '',
        id_departamento: null
      },
    }
  },

  methods: {
    salvarInformativo(fecharModal) {
      this.load = true;
      this.$http.post("artigo/" + this.token, this.dados)
        .then(res => {
          this.load = false;
          this.$swal("Sucesso!","Informativo " + (this.editar ? "editado" : "cadastrado") + " com sucesso.");
          fecharModal(true);

        }, res => {
          console.log(res);
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
    },

    clear() {
      this.editar = false;
      this.dados = {
        titulo: '',
        descricao: '',
        texto: '',
        id_departamento: null
      }
    }
  },

  created() {
    this.$parent.$on('salvar', (callback) => {
      this.salvarInformativo(callback);
    });

    this.$parent.$on('loadDadosModal', (dados) => {

      if (dados) {
        this.editar = true;

        this.dados = {
          id_artigo: dados.id_artigo,
          titulo: dados.titulo,
          descricao: dados.descricao,
          texto: dados.texto,
          id_departamento: dados.id_departamento,
          fg_ativo: dados.fg_ativo,
        };

        console.log(this.dados)

      } else {
        this.clear();
      }

      this.$helpers.focusrElement('titulo');
    })
  },
}
</script>

<style scoped>

</style>
