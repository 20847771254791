<template>
  <div>
    <div :id="modal" class="modal-full">
      <div class="modal__inner">
        <div class="btn-close">
          <button type="button" class="btn btn-light" @click="closeModal"><i class="fa fa-times mr-1"></i> Fechar</button>
        </div>
        <div class="modal-full__container">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import '../assets/css/modal-full.scss'

  export default {
    name: "ModalFull",

    data() {
      return {
        modal: ''
      }
    },

    methods: {
      openModal() {
        $('body').addClass('modal-open');
        $('#' + this.modal).addClass('modal-full__open');
      },

      closeModal() {
        $('#' + this.modal).removeClass('modal-full__open');
      },

      salvar() {
        this.closeModal();
      }
    },

    created() {
      this.modal = this.$helpers.ID();

      this.$parent.$on('openModalFull', () => {
        this.openModal();
      });
    }
  }
</script>
