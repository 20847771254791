<template>
  <div class="container-fluid">
    <loading v-show="load"/>
    <div v-show="!load">
      <form @submit.prevent="salvar">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 class="h3 mb-0 text-gray-800">{{dados.nome_prefeitura}}</h1>
          <div class="mt-3 mt-sm-0">
            <button class="btn btn-primary btn-block">Salvar</button>
          </div>
        </div>
        <modal-menu :menus="menus" :selected="menu" @changeMenu="changeMenu"/>

        <div v-show="menu === 1">
          <div class="card shadow mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="nome_prefeitura">Prefeitura</label>
                        <input type="text" class="form-control" id="nome_prefeitura" placeholder="Ex: Prefeitura Municipal de..." v-model="dados.nome_prefeitura">
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="nome_prefeito">Prefeito(a)</label>
                        <input type="text" class="form-control" id="nome_prefeito" placeholder="Nome do prefeito" v-model="dados.prefeito">
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="nome_vice_prefeito" >Vice-Prefeito(a)</label>
                        <input type="text" class="form-control" id="nome_vice_prefeito" placeholder="Nome do vice-prefeito" v-model="dados.vice_prefeito">
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0">Gestão</label>
                        <select name="gestao" id="gestao" v-model="dados.administracao" class="form-control">
                          <option :value="null">Selecione</option>
                          <option value="2021 / 2024">2021 / 2024</option>
                          <option value="2025 / 2028">2025 / 2028</option>
                          <option value="2028 / 2031">2028 / 2031</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="nome_ouvidor" >Ouvidor(a) Responsável</label>
                        <input type="text" class="form-control" id="nome_ouvidor" placeholder="Responsável pela ouvidoria" v-model="dados.ouvidor"/>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="telefone">Telefone</label>
                        <the-mask typeof="number" :mask="['(##) ####-####', '(##) #####-####']" class="form-control" id="telefone" placeholder="(##) ####-####" required v-model="dados.telefone"/>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="whatsapp">Whatsapp</label>
                        <the-mask typeof="number" :mask="['(##) ####-####', '(##) #####-####']" class="form-control" id="whatsapp" placeholder="(##) #####-####" required v-model="dados.whatsapp"/>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="email">Email</label>
                        <input type="text" class="form-control" id="email" placeholder="Email para contato" v-model="dados.email"/>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="funcionamento">Funcionamento</label>
                        <input type="text" class="form-control" id="funcionamento" placeholder="Ex: Seg a Ter - 18:00 às 19:00" v-model="dados.funcionamento"/>
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="descricao_prefeitura" class="font-weight-bold mb-0">Descrição (Slogan)</label>
                        <textarea id="descricao_prefeitura" placeholder="Ex: Prefeitura Municipal de São Tomás de Aquino a serviço de todos!" rows="4" class="form-control" v-model="dados.descricao"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="menu === 2">
          <div class="row">
            <div class="col-md-12">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="logradouro">Endereço</label>
                        <input type="text" class="form-control" id="logradouro" placeholder="Ex: Rua do Comércio" v-model="dados.logradouro"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="numero">Número</label>
                        <input type="text" class="form-control" id="numero" placeholder="Ex: 1234" v-model="dados.numero"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="bairro">Bairro</label>
                        <input type="text" class="form-control" id="bairro" placeholder="Ex: Centro" v-model="dados.bairro"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="cep">Cep</label>
                        <input type="text" class="form-control" id="cep" placeholder="Ex: 14402-223" v-model="dados.cep"/>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="complemento">Complemento</label>
                        <input type="text" class="form-control" id="complemento" placeholder="Ex: Ao lado da Praça Municipal" v-model="dados.complemento"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="menu === 3">
          <div class="row">
            <div class="col-md-12">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0" for="site_institucional">Site</label>
                        <input type="text" class="form-control" id="site_institucional" placeholder="Site da Prefeitura" v-model="dados.site"/>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0">Instagram</label>
                        <input type="text" class="form-control" id="url_instagram" placeholder="Url do Instagram da Prefeitura" v-model="dados.instagram">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-weight-bold mb-0">Facebook</label>
                        <input type="text" class="form-control" id="url_facebook" placeholder="Url do Facebook da Prefeitura" v-model="dados.facebook">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
  import ModalMenu from "../../components/commons/ModalMenu";
  import Loading from "../../components/commons/Loading";
  import Tooltip from "../../components/commons/Tooltip";

  export default {
    name: "Prefeitura",
    components: {Tooltip, Loading, ModalMenu},
    data() {
      return {
        token: localStorage.getItem('key'),
        menus: [
          { nome: 'Dados', active: true },
          { nome: 'Endereço', active: true },
          { nome: 'Links', active: true }
        ],
        menu: 1,
        load: false,

        horario: {
          inicio: '',
          fim: '',
        },

        dados: {
          id_prefeitura: '',
          id_cidade: '',
          token: '',
          nome_prefeitura: '',
          descricao: '',
          administracao: '',
          prefeito: '',
          vice_prefeito: '',
          ouvidor: '',
          cep: '',
          logradouro: '',
          numero: '',
          bairro: '',
          complemento: '',
          email: '',
          telefone: '',
          whatsapp: '',
          funcionamento: '',
          site: '',
          instagram: '',
          facebook: '',
          status: ''
        }
      }
    },

    methods: {
      getPrefeitura() {
        this.load = true;
        this.$http.get('prefeitura/' + this.token).then(response => {
          this.dados = response.data;
          this.load = false;

        }, res => {
          this.load = false;
          this.$swal(res.data.result,res.data.msg);
        });
      },

      changeMenu(m) {
        this.menu = m;
      },

      salvar() {
        this.load = true;
        this.$http.post("prefeitura/" + this.token, this.dados)
          .then(res => {
            this.load = false;
            this.$swal("Sucesso!", "Dados editados com sucesso.");

          }, res => {
            console.log(res);
            this.load = false;
            this.$swal(res.data.result,res.data.msg);
          });
      }
    },

    mounted() {
      this.getPrefeitura();
    }
  }
</script>

<style scoped>

</style>
