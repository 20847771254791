<template>
  <div>
    <div class="bg-home">
      <div class="filtro text-white">
        <div class="container">
          <div class="header d-flex justify-content-between align-items-center">
            <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/idv/logo-horizontal2.png" alt="Logo Solicitae" style="width: 125px">
            <a href="/login" class="text-white font-weight-bold text-decoration-none">Entrar</a>
          </div>
          <div class="home-content">
            <h2>Venha construir a cidade do <b>amanhã</b> com a gente hoje!</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div id="aboutUs">
        <div class="mb-5">
          <h2 class="text-primary text-center font-weight-bold">Quem Somos</h2>
          <hr>
        </div>
        <div class="row">
          <div class="col-md-3 mb-4">
            <h3 class="text-dark" style="line-height: 42px">
              Aproximar o <b class="text-primary">cidadão</b> com a administração municipal, essa é a nossa <b class="text-secondary">missão!</b>
            </h3>
          </div>
          <div class="col-md-9">
            <p>
              Nós queremos dar à população o poder de colaborar com questões do setor público através de publicações de zeladoria urbana, participação em tomadas de decisões e em consultas de avaliação de serviços públicos.
            </p>
            <p>
              Além da ferramenta de participação social, o Solicita-e atua diretamente com os gestores públicos no incentivo e promoção de melhorias para gestões mais eficientes e inovadoras na administração pública.
            </p>
            <p>
              A nossa missão é auxiliar as entidades públicas a se tornarem mais eficientes e responsivas às demandas da sociedade.
            </p>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="card bg-primary shadow-sm">
          <div class="card-body text-center">
            <div style="padding: 64px 0">
              <h2 class="text-white font-weight-bold">Fale conosco</h2>
              <p class="text-white">Quer saber mais como podemos ajudar a administração da sua cidade?</p>
              <div class="mt-4">
                <a href="https://wa.me/5516993626508" target="_blank" class="btn btn-secondary">Fale Conosco</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from "../components/commons/Loading";
  export default {
    name: 'Home',
    components: {Loading},
    data() {
      return {
      }
    },
    methods: {
    },
  }
</script>
<style scoped>
  hr {
    width: 48px;
    border-color: #F47C34;
    border-width: 2px;
  }
  .bg-home {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/idv/bg-solicitae.jpg);
    background-position: center;
  }

  .bg-home .filtro {
    background-color: rgba(0,0,0,0.6);
  }

  .header {
    padding-top: 16px;
  }

  .home-content {
    padding: 108px 0 164px;
    text-align: center;
  }

  #aboutUs {
    margin: 64px 0 72px;
  }
</style>
