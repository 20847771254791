<template>
  <div class="container-fluid">
    <loading v-show="load"/>
    <div v-show="!load">
      <div class="row justify-content-between align-items-right">
        <div class="col-sm-6 col-lg-2 mb-3">

        </div>
        <div class="col-sm-6 mb-3 text-right">
          <button class="btn btn-primary" @click="selServico()"><i class="fas fa-plus"></i> Cadastrar</button>
        </div>
      </div>
      <div class="mb-4">
        <modal-menu :menus="menus" :selected="menu" @changeMenu="changeMenu"/>
      </div>

      <div v-if="servicos.length">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="table-responsive" style="min-height: 100px">
              <table class="table">
                <thead class="border-bottom">
                <tr>
                  <th>Serviços</th>
                  <th style="width: 200px">&nbsp;</th>
                  <th style="width: 100px">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="s in servicos">
                    <td @click="selServico(s)" class="pointer align-middle">{{s.nome_servico}}</td>
                    <td class="text-center align-middle text-nowrap">
                      <button class="btn btn-sm mr-1" :class="s.fg_ativo === '1' ? 'btn-primary' : 'btn-light'" @click="toggleServico(s, '1')">Ativado</button>
                      <button class="btn btn-sm" :class="s.fg_ativo === '2' ? 'btn-primary' : 'btn-light'" @click="toggleServico(s, '2')">Desativado</button>
                    </td>
                    <td class="align-middle text-nowrap">
                      <button class="btn btn-primary btn-sm mr-2" title="Editar Departamento" @click="selServico(s)"><i class="fas fa-pencil-alt"></i></button>
                      <button class="btn btn-dark btn-sm" title="Remover Departamento" @click="deletarServico(s)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="!departamentos.length">
        <div class="d-flex justify-content-center align-items-center" style="padding-top: 10vh">
          <div class="text-center">
            <h2>Precisamos de um novo Departamento!</h2>
            <p>Cadastre um novo departamento.</p>
            <div class="mt-4">
              <router-link to="/departamentos" class="btn btn-primary">Cadastrar Departamento</router-link>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="d-flex justify-content-center align-items-center" style="padding-top: 10vh">
          <div class="text-center">
            <h2>{{menu === 1 ? "Novo Serviço" : "Nova Ouvidoria"}}</h2>
            <p>Clique no botão abaixo para cadastrar {{menu === 1 ? "um novo serviço" : "uma nova ouvidoria"}}.</p>
            <div class="mt-4">
              <button class="btn btn-primary" @click="selServico()">Cadastrar</button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <modal @fechar="getServicos()">
      <servico-formulario :departamentos="departamentos"/>
    </modal>
  </div>
</template>

<script>
  import draggable from "vuedraggable"
  import Modal from "../../components/Modal";
  import Loading from "../../components/commons/Loading";
  import ServicoFormulario from "./ServicoFormulario";
  import ModalMenu from "../../components/commons/ModalMenu";

  export default {
    name: 'ListaServicos',
    components: {ModalMenu, draggable, ServicoFormulario, Loading, Modal},
    data() {
      return {
        load: false,
        token: localStorage.getItem('key'),
        departamentos: [],
        servicos: [],


        pesquisa: {
          tipo_solicitacao: 1
        },

        menu: 1,
        menus: [
          { nome: 'Serviços', active: true },
          { nome: 'Ouvidoria', active: true }
        ],
      }
    },

    methods: {
      buscarDepartamentos() {
        if (this.load) {
          return;
        }

        this.load = true;
        this.$http.get('departamento/' + this.token).then(response => {
          this.departamentos = response.data;
          this.getServicos();

        }, res => {
          this.load = false;
          this.$swal(res.data.msg);
        });
      },

      getServicos() {
        this.load = true;
        this.$http.get('servicos/' + this.token, {params: this.pesquisa}).then(response => {
          this.servicos = response.data;
          this.load = false;

        }, res => {
          console.log(res);
          this.load = false;
          this.$swal(res.data.msg);
        });
      },

      selServico(dados) {
        dados = dados ? dados : { tipo_solicitacao: this.menu };
        this.$emit('openModal', dados);
      },

      changeMenu(m) {
        this.menu = m;
        this.pesquisa.tipo_solicitacao = m;
        this.getServicos();
      },

      toggleServico(s, status) {
        s.fg_ativo = status;
        this.$http.post("servicos/" + this.token, s).then(res => {}, res => {
          this.$swal(res.data.msg);
        });
      },

      deletarServico(s) {
        this.$swal({
          text: "Deseja realmente deletar este serviço?",
          buttons: ["Cancelar", "Sim!"],
          dangerMode: true,
        }).then((result) => {
          if (result) {
            this.load = true;
            this.$http.post('servicos/remover/' + this.token, s)
              .then(() => {
                this.$swal("Sucesso","Serviço deletado com sucesso!");
                this.getServicos();

              }, res => {
                this.load = false;
                this.$swal(res.data.result,res.data.msg);
              });
          }
        });
      }
    },

    mounted() {
      this.buscarDepartamentos();
    },
  }
</script>
