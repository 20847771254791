<template>
  <div class="container-fluid">

    <loading v-if="load" text="Buscando relatórios"/>

    <div v-else>
      <div class="mt-4 d-lg-flex justify-content-end justify-content-lg-between align-items-center">
        <h5 class="font-weight-bolder m-0">Dahsboard</h5>
        <select class="form-control bg-white" title="Mes" v-model="pesquisa.mes" @change="buscarDados()" style="width: 200px">
          <option :value="mes.value" v-for="mes in meses">{{mes.nome}}</option>
        </select>
      </div>

      <div class="row mt-4 justify-content-end">
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-warning shadow-sm h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Aguardando</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{abertas}}</div>
                  <div class="small">Solicitações</div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-file-alt fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow-sm h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Serviços</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{servicos}}</div>
                  <div class="small">Mes de {{nome_mes}}</div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-file-alt fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow-sm h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Ouvidoria</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{ouvidoria}}</div>
                  <div class="small">Mes de {{nome_mes}}</div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-file-alt fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow-sm h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total</div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{total}}</div>
                  <div class="small">Mes de {{nome_mes}}</div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-file-alt fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-4">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Status ({{nome_mes}})</h6>
            </div>
            <div class="card-body">
              <div style="height: 400px">
                <tipo-pedido :height="380" :dados="dados.status" v-if="dados.status.length > 0"/>
                <div class="d-flex justify-content-center align-items-center" style="height: 270px" v-else>
                  <div class="text-center">
                    Você não tem solicitações neste mês!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8">
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Solicitações por Departamento ({{nome_mes}})</h6>
            </div>
            <div class="card-body" style="height: 440px">
              <div style="height: 380px">
                <tipo-vendas :departamentos="dados.departamentos" :height="410" v-if="dados.departamentos.length"/>
                <div class="d-flex justify-content-center align-items-center" style="height: 270px" v-else>
                  <div class="text-center">
                    Você não tem solicitações neste mês!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import TipoVendas from "../components/graficos/TipoVendas";
import Loading from "../components/commons/Loading";
import TipoPedido from "../components/graficos/TipoPedido";
export default {
  name: 'Home',
  components: {TipoPedido, Loading, TipoVendas},
  data() {
    return {
      token: localStorage.getItem('key'),
      load: true,
      meses: [{value: 1, nome: 'Janeiro'},{value: 2, nome: 'Fevereiro'},{value: 3, nome: 'Março'},{value: 4, nome: 'Abril'},{value: 5, nome: 'Maio'},{value: 6, nome: 'Junho'},{value: 7, nome: 'Julho'},{value: 8, nome: 'Agosto'},{value: 9, nome: 'Setembro'},{value: 10, nome: 'Outubro'},{value: 11, nome: 'Novembro'},{value: 12, nome: 'Dezembro'}],

      dados: {
        tipo: [],
        departamentos: [],
        status: []
      },

      pesquisa: {
        mes: 1
      },

      nome_mes: '',
      total: 0,
      servicos: 0,
      ouvidoria: 0,
      abertas: 0
    }
  },

  methods: {
    buscarDados() {
      this.load = true;
      this.nome_mes = this.meses.find((m) => m.value === this.pesquisa.mes).nome;

      this.$http.get('relatorio/dashboard/' + this.token, {params: this.pesquisa}).then(response => {
        if (response.data) {
          this.dados = response.data;

          if (this.dados.tipo.length) {
            const servico = this.dados.tipo.find((s) => s.tipo_solicitacao === '1');
            this.servicos = servico ? servico.qtd : 0;

            const ouvidoria = this.dados.tipo.find((s) => s.tipo_solicitacao === '2');
            this.ouvidoria = ouvidoria ? ouvidoria.qtd : 0;
          }

          this.dados.tipo.forEach((s) => {
            this.total += parseInt(s.qtd);
          });

          const abertas = this.dados.status.find((s) => s.status === '1');
          this.abertas = abertas ? abertas.qtd : 0;
        }

        this.load = false;

      }, res => {
        this.load = false;
        this.$swal(res.data.msg);
      });

    }
  },

  mounted() {
    this.pesquisa.mes = new Date().getMonth() + 1;
    this.nome_mes = this.meses.find((m) => m.value === this.pesquisa.mes).nome;
    this.buscarDados();
  }
}
</script>
