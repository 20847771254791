<template>
  <div class="container-fluid">
    <loading v-show="load" text="Carregando os dados"/>

    <div v-show="!load">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Solicitações</h1>
      </div>
      <div>
        <div class="card shadow mb-4">
          <div class="card-body text-md-left">
            <div class="row">
              <div class="col-md-3 mb-3">
                <label class="small font-weight-bold mb-0">Tipo</label>
                <select id="relatorio" class="form-control" v-model="pesquisa.tipo_solicitacao" @change="buscarSolicitacoes">
                  <option value="">Todos</option>
                  <option value="1">Serviços</option>
                  <option value="2">Ouvidoria</option>
                </select>
              </div>
              <div class="col-md-2 mb-3">
                <label class="small font-weight-bold mb-0">Status</label>
                <select id="status" class="form-control" v-model="pesquisa.status">
                  <option value="">Todos</option>
                  <option value="1">Andamento</option>
                  <option value="4">Finalizados</option>
                  <option value="5">Cancelados</option>
                </select>
              </div>
              <div class="col-md-3 mb-3">
                <label class="small font-weight-bold mb-0">Data</label>
                <input type="date" id="data" class="form-control" v-model="pesquisa.data">
              </div>
              <div class="col-md-2 mb-3">
                <label class="small font-weight-bold mb-0">Protocolo</label>
                <input type="text" id="nome" class="form-control" placeholder="Nº do protocolo" v-model="pesquisa.protocolo">
              </div>
              <div class="col-md-2 mb-3">
                <label>&nbsp;</label>
                <button class="btn btn-primary btn-block" @click="buscarSolicitacoes()">Pesquisar</button>
              </div>
            </div>
          </div>
        </div>

        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="table-responsive" style="min-height: 100px">
              <table class="table">
                <thead class="border-bottom">
                  <tr>
                    <th>Protocolo</th>
                    <th>Solicitante</th>
                    <th>Serviço</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="pointer" v-for="s in solicitacoes" @click="selSolicitacao(s.id_solicitacao)">
                    <td>{{s.protocolo}}</td>
                    <td>{{s.nome_cidadao}}</td>
                    <td>{{s.nome_servico}}</td>
                    <td>{{s.status | status}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :preview="true" @fechar="fecharSolicitacao">
      <solicitacoes-formulario :departamentos="departamentos" @atualizar="buscarSolicitacoes"/>
    </modal>

  </div>
</template>

<script>
import Modal from "../../components/Modal";
import Loading from "../../components/commons/Loading";
import SolicitacoesFormulario from "./SolicitacoesFormulario";

export default {
  name: 'ListaSolicitacoes',
  components: {Loading, Modal, SolicitacoesFormulario},
  data() {
    return {
      token: localStorage.getItem('key'),
      load: false,

      solicitacoes: [],
      departamentos: [],

      pesquisa: {
        data: '',
        status: '1',
        protocolo: '',
        tipo_solicitacao: '',
        page: 0
      }
    }
  },

  methods: {
    buscarDepartamentos() {
      this.$http.get('departamento/' + this.token).then(response => {
        this.departamentos = response.data;
      }, res => {
        this.$swal(res.data.msg);
      });
    },

    buscarSolicitacoes() {
      if (this.load) {
        return;
      }

      this.load = true;
      this.$http.get('solicitacao/' + this.token, {params: this.pesquisa}).then(response => {
        this.solicitacoes = response.data;
        this.load = false;

      }, res => {
        this.load = false;
        this.$swal(res.data.result,res.data.msg);
      });
    },

    fecharSolicitacao() {
      if (this.$route.name !== "ListaSolicitacoes") {
        this.$router.push('/solicitacoes');
      }
    },

    selSolicitacao(id) {
      if (this.$route.params.id !== id) {
        this.$router.push('/solicitacoes/' + id);
      }
    },
  },

  mounted() {
    this.buscarDepartamentos();
    this.buscarSolicitacoes();
    // this.pesquisa.data = new Date().toDateInputValue();

    if (this.$route.name === "Solicitacao") {
      this.$emit('openModal', this.$route.params.id);
    }
  },

  watch: {
    $route(to, from) {
      if (to.name === 'Solicitacao') {
        this.$emit('openModal', to.params.id);

      } else {
        this.$emit('closeModal');
      }
    }
  }
}
</script>
