<template>
  <div>
    <loading v-show="load" text="Carregando os dados"/>
    <div v-show="!load">
      <div class="mb-2">
        <h4 class="font-weight-bold mb-0">Protocolo: {{dados.protocolo}}</h4>
      </div>

      <modal-menu :menus="menus" :selected="menu" @changeMenu="changeMenu"/>

      <div v-show="menu === 1">
        <div class="d-flex justify-content-between mb-3">
          <div>
            <div class="m-0">
              {{dados.tipo_solicitacao | tipo }}:
              {{dados.id_solicitacao}} - {{dados.data_solicitacao}}
            </div>
            <h6 class="m-0"><b>Departamento:</b> {{dados.nome_departamento}}</h6>
          </div>
          <div class="font-weight-bold">
            <span class="badge badge-danger" v-if="dados.status === '1'">Aguardando</span>
            <span class="badge badge-info" v-if="dados.status === '2'">Em Análise</span>
            <span class="badge badge-dark" v-if="dados.status === '3'">Processando</span>
            <span class="badge badge-dark" v-if="dados.status === '4'">Finalizado</span>
            <span class="badge badge-danger" v-if="dados.status === '5'">Cancelado</span>
          </div>
        </div>
        <div class="mb-3" v-show="dados.status === '5'">
          <b class="text-danger">Cancelado:</b>
          {{dados.obs_cancelamento ? dados.obs_cancelamento : 'Cancelado pelo cidadão'}}
        </div>
        <div class="border p-2 mb-3" v-if="dados.anonimo === '0'">
          <h5 class="font-weight-bold m-0">{{dados.nome_cidadao}}</h5>
          <div>
            Nº de solicitações: {{dados.qtd_solicitacao > 1 ? dados.qtd_solicitacao : 'Primeira Solicitação'}}
          </div>
          <div>
            <a class="text-info" :href="'tel:' + dados.telefone" v-if="dados.telefone">
              <i class="fa fa-mobile-alt"></i> {{dados.telefone | phone}}
            </a>
            <span class="mx-1"> | </span>
            <a class="text-info" target="_blank" :href="'mailto:' + dados.email" v-if="dados.email">
              <i class="fa fa-envelope"></i> {{dados.email}}
            </a>
          </div>
        </div>
        <div class="border bg-light p-3 mb-3" v-else>
          <h6 class="font-weight-bold m-0"><i class="fa fa-user-secret mr-2"></i> Solicitação Anônima</h6>
        </div>
        <div class="border p-2 mb-3" v-show="dados.logradouro">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-success font-weight-bold m-0">Local:</h6>
              {{dados.logradouro}}
              <span v-if="dados.numero">, {{dados.numero}}</span>
              <span v-if="dados.bairro">, {{dados.bairro}}</span>
              <span class="d-block" v-if="dados.complemento">Referência: {{dados.complemento}}</span>
            </div>
          </div>
        </div>
        <div class="my-3">
          <div class="px-1 pt-3 border-top">
            <h6 class="font-weight-bold"># {{dados.nome_servico}}</h6>
            <div>
              <p class="mb-0" v-if="dados.assunto"><b>Assunto:</b> {{dados.assunto}}</p>
              <p class="mb-0" v-if="dados.mensagem"><b>Mensagem:</b> {{dados.mensagem}}</p>
            </div>
          </div>
        </div>
        <div class="my-3" v-if="dados.arquivos.length">
          <div class="px-1 pt-3 border-top">
            <h6 class="font-weight-bold">Imagens</h6>
            <div class="img-solicitacao">
              <a href="javascript:" class="text-decoration-none" v-for="a in dados.arquivos" @click="openImagem(a)">
                <img :src="a.url_imagem" alt="Imagem">
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-show="menu === 2">
        <div class="mb-4">
          <p class="mb-0" v-if="dados.nome_servico"><b>Serviço:</b> {{dados.nome_servico}}</p>
          <p class="mb-0" v-if="dados.assunto"><b>Assunto:</b> {{dados.assunto}}</p>
          <p class="mb-0" v-if="dados.mensagem"><b>Mensagem:</b> {{dados.mensagem}}</p>
          <p class="mb-0" v-if="dados.mensagem"><b>Status Atual: </b>
            <span class="badge badge-danger" v-if="dados.status === '1'">Aguardando</span>
            <span class="badge badge-info" v-if="dados.status === '2'">Em Análise</span>
            <span class="badge badge-dark" v-if="dados.status === '3'">Processando</span>
            <span class="badge badge-dark" v-if="dados.status === '4'">Finalizado</span>
            <span class="badge badge-danger" v-if="dados.status === '5'">Cancelado</span>
          </p>
        </div>
        <div class="card mb-4 shadow-sm">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <label><i class="fa fa-clone mr-3"></i> {{resposta.status | status}}</label>
              <select class="form-control d-inline-block" style="width: 150px" v-model="resposta.status" @change="respostaAutomatica">
                <option value="2">Análisando</option>
                <option value="3">Processando</option>
                <option value="4">Finalizado</option>
                <option value="5">Cancelado</option>
              </select>
            </div>
            <div class="mt-2">
              <label class="font-weight-bold mb-0">Mensagem</label>
              <textarea title="resposta" rows="3" class="form-control" placeholder="Escreva aqui uma mensagem" v-model="resposta.mensagem" style="resize: vertical"></textarea>
            </div>
            <div class="mt-3 text-right">
              <button class="btn btn-primary" @click="addResposta"><i class="fas fa-plus"></i> Adicionar</button>
            </div>
          </div>
        </div>
        <h6 class="font-weight-bold pt-3 border-top">Histórico</h6>
        <div class="pb-5">
          <div class="card mb-2" v-for="r in dados.respostas" @click="mostrarResposta(r.id_resposta)">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center pointer">
                <div>
                  <i class="fa fa-clone mr-2"></i> {{r.status | status}} - <span class="small">{{r.data_resposta}}</span>
                </div>
                <button class="btn btn-sm" type="button"><i class="fa fa-chevron-down"></i></button>
              </div>
              <div class="border-top mt-3 pt-3" v-show="expandir.includes(r.id_resposta)">
                <div><b>Servidor:</b> {{r.nome}}</div>
                <div><b>Mensagem:</b> {{r.mensagem}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="menu === 3" v-if="departamentos">
        <div class="card shadow-sm">
          <div class="card-body">
            <form @submit.prevent="encaminhar">
              <div class="row align-items-end">
                <div class="col-md-6 mb-2">
                  <label class="mb-0">Selecione um departamento</label>
                  <select class="form-control" v-model="departamento" required>
                    <option :value="null" disabled>Selecione</option>
                    <option :value="d.id_departamento" v-for="d in departamentos" v-if="d.id_departamento !== dados.id_departamento">{{d.nome_departamento}}</option>
                  </select>
                </div>
                <div class="col-md-6 mb-2">
                  <button class="btn btn-primary">Encaminhar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <modal-full>
      <div v-if="imgSelecionada">
        <img :src="imgSelecionada" class="img-modal">
      </div>
    </modal-full>

  </div>
</template>

<script>
  import ModalMenu from "../../components/commons/ModalMenu";
  import UploadImage from "../../components/UploadImage";
  import Loading from "../../components/commons/Loading";
  import ModalFull from "../../components/ModalFull";

  export default {
    name: 'ServicosFormulario',
    components: {ModalFull, Loading, UploadImage, ModalMenu},
    props: ['departamentos'],

    data() {
      return {
        load: false,
        token: localStorage.getItem('key'),
        menus: [
          {nome: 'Dados', active: true},
          {nome: 'Respostas', active: true},
          {nome: 'Encaminhar', active: true},
        ],
        menu: 1,
        expandir: [],

        dados: {
          id_solicitacao: '',
          data_solicitacao: '',
          status: '',
          nome_departamento: '',
          protocolo: '',
          qtd_solicitacao: '',
          obs_cancelamento: '',
          logradouro: '',
          numero: '',
          bairro: '',
          complemento: '',
          nome_servico: '',
          assunto: '',
          mensagem: '',
          respostas: [],
          arquivos: []
        },

        resposta: {
          status: '2',
          mensagem: '',
        },

        imgSelecionada: '',
        departamento: null
      }
    },

    methods: {
      buscarSolicitacaoId(id) {
        this.load = true;
        this.$http.get('solicitacao/' + this.token + '/' + id).then(response => {
          if (response.data) {
            this.dados = response.data;
            this.menus[1].active = !!this.dados.id_cidadao;

            if (this.dados.status < 4) {
              this.resposta.status = (parseInt(this.dados.status) + 1).toString();
            }

            this.respostaAutomatica();
          }

          this.departamento = null;
          this.load = false;

        }, res => {
          this.load = false;
          this.$swal(res.data.result, res.data.msg);
          this.$router.push('/solicitacoes');
        });
      },

      addResposta() {
        this.$swal({
          text: "Deseja enviar esta nova atualização de status?",
          buttons: ["Cancelar", "Enviar!"],
          dangerMode: true,
        }).then((res) => {
          if (res) {
            this.dados.respostas.unshift({
              mensagem: this.resposta.mensagem,
              status: this.resposta.status
            });

            this.salvarSolicitacao();
          }
        });
      },

      salvarSolicitacao() {
        const id = this.dados.id_solicitacao;
        this.load = true;

        this.$http.post('solicitacao/' + this.token + '/' + id, this.dados).then(response => {
          this.load = false;
          this.$emit('atualizar');

          if (response.data) {
            this.buscarSolicitacaoId(id)
          }

        }, res => {
          this.load = false;
          this.$swal(res.data.msg);
        });
      },

      encaminhar() {
        this.$swal({
          text: "Deseja encaminhar esta solicitação?",
          buttons: ["Cancelar", "Encaminhar!"],
          dangerMode: true,
        }).then((res) => {
          if (res) {
            const id = this.dados.id_solicitacao;
            this.load = true;

            const dados = {
              id_solicitacao: this.dados.id_solicitacao,
              id_departamento: this.departamento
            };

            this.$http.post('solicitacao/' + this.token + '/encaminhar', dados).then(response => {
              this.load = false;

              if (response.data) {
                this.$swal("Encaminhamento realizado com sucesso!");
                this.menu = 1;
                this.$emit('atualizar');
                this.buscarSolicitacaoId(id);
              }

            }, res => {
              this.load = false;
              this.$swal(res.data.msg);
            });
          }
        });
      },

      respostaAutomatica() {
        switch (this.resposta.status) {
          case '2':
            this.resposta.mensagem = 'Recebemos sua solicitação e já estamos analisando!';
            break;
          case '3':
            this.resposta.mensagem = 'Sua solicitação foi encaminhada para o setor responsável';
            break;
          case '4':
            this.resposta.mensagem = 'Solicitação finalizada. Obrigado!';
            break;
          case '5':
            this.resposta.mensagem = 'Infelizmente sua solicitação foi cancelada';
            break;
        }
      },

      changeMenu(m) {
        this.menu = m;
      },

      mostrarResposta(id) {
        if (!this.expandir.includes(id)) {
          this.expandir.push(id);
        } else {
          this.expandir = this.expandir.filter(el => el !== id)
        }
      },

      openImagem(a) {
        this.imgSelecionada = a.url_imagem;
        this.$emit('openModalFull');
      }
    },

    created() {
      this.$parent.$on('loadDadosModal', (id) => {
        this.menu = 1;
        this.resposta.status = '2';
        this.buscarSolicitacaoId(id);
      });
    },
  }
</script>

<style scoped>
  .img-solicitacao img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 4px;
  }

  .img-modal {
    width: 100%;
  }
</style>
